import {
  IonBadge,
  IonContent,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { useQuery } from "@tanstack/react-query";
import { hubIconMapper } from "admin/src/constants/hub/hub-icons-mapper";
import { RouteConfigView } from "admin/src/server/mappers/app-route/app-route-config/app-route-config";
import { SocietyTagView } from "admin/src/server/mappers/tag/society-tag";
import NewAppButton from "admin/src/ui/components/common/newform/NewAppButton";
import { getChats } from "hub/src/api/chatAPI";
import { SocietyLogo } from "hub/src/components/common/SocietyLogo";
import ChevronBackIcon from "hub/src/components/icons/ChevronBackIcon";
import LogOutOutlineIcon from "hub/src/components/icons/LogOutOutlineIcon";
import useDeviceInfo from "hub/src/hooks/useDeviceInfo";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { useHistory, useLocation } from "react-router";
import { Chat } from "hub/src/types/chat";
import {
  formatRoutesList,
  FormattedUserRoute,
} from "hub/src/utils/formatMenuItems";
import { mapPathParamsToUrl } from "hub/src/utils/mapPathParamsToUrl";
import AdminImpersonateInfo from "../admin/AdminImpersonateInfo";
import MenuItemDisclosure from "./MenuItemDisclosure";

const MainMenu = () => {
  const session = useHubSessionContext();
  const location = useLocation();
  const history = useHistory();
  const device = useDeviceInfo();

  const getUnreadChatCount = (data: Chat[]): number => {
    return data.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.unreadMsgCount;
    }, 0);
  };

  const unReadChatCountQuery = useQuery({
    queryKey: ["chats"],
    queryFn: () => getChats(),
    select: (data: Chat[]) => {
      return getUnreadChatCount(data);
    },
    refetchOnWindowFocus: false,
    retry: 0,
    enabled:
      !!session.societyUserId &&
      session.society?.societySettingsPublic?.ablyEnabled,
  });

  // const { data: statsResp } = useApiQuery(
  //   GetClientDashboardStats,
  //   {
  //     societyId: getSocietyId().toString(),
  //     profileId: getProfileId().toString(),
  //   },
  //   {},
  //   {},
  //   {
  //     staleTime: -1,
  //     enabled: !!getProfileId(),
  //   },
  // );

  // Had to add an extra check here.
  // There's a weird bug where the response is not coming back as an array.
  // TODO: Need to look into this more. Not able to recreate after clearing all caches.
  // const newReviewStats = (Array.isArray(statsResp?.data?.body) &&
  //   // For review, we only care about New review counts.
  //   statsResp?.data?.body?.find((stat) => stat.statusName === "New")) as
  //   | any
  //   | undefined;

  const getMenuItemBadge = (routeComponentName: string) => {
    // Map badge data based on the component's name from route.
    // Make sure to use component names that match the names stored in the database.
    // Similar to how we handle UserHub Routes.

    const badgeData: Record<string, number | undefined> = {
      chatsPage: unReadChatCountQuery.data,
      //submissionWorkflowReviewsPage: newReviewStats?.statusCount ?? 0,
      // submissionAssignmentPage: session.profile?.submissionAssignments?.filter(
      //   (submissionAssignment: any) => {
      //     return submissionAssignment.isActive;
      //   },
      // ).length,
      submissionAssignmentPage: 0,
    };

    const badgeValue = badgeData[routeComponentName];

    // Display a badge if there's a value.
    if (badgeValue) {
      return <IonBadge className="ml-4">{badgeValue}</IonBadge>;
    }

    return null;
  };

  const MenuItemLink = ({
    formattedRoute: route,
    overrideLabel,
    testId,
  }: {
    formattedRoute: FormattedUserRoute;
    overrideLabel?: string;
    testId?: string;
  }) => {
    const IconComponent = route.iconName
      ? hubIconMapper[route.iconName]
      : undefined;

    return (
      <div className="pl-2">
        <button
          data-testid={testId}
          onClick={() => {
            if (route.externalRoute) {
              route.openInNewTab
                ? window.open(route.url)
                : (window.location.href = route.url);
            } else {
              history.push(mapPathParamsToUrl(route.url, route.pathParam));
            }
          }}
          className={`sidebar-menu-selector flex flex-row w-full rounded-lg items-center text-start group ${
            route.label === "Communication" && "sm:hidden"
          }`}
        >
          {IconComponent && (
            <IconComponent className="sidebar-menu-svg ion-like-icon" />
          )}
          <div
            className={`sidebar-menu-title ${
              route.label === "Communication" && "sm:hidden"
            }`}
          >
            {overrideLabel ?? route.label}
          </div>
          {getMenuItemBadge(route.componentName)}
        </button>
      </div>
    );
  };

  const renderMenuItems = (routeConfigs: RouteConfigView[]) => {
    const formattedRoutes = formatRoutesList(routeConfigs);

    return formattedRoutes.map((route) => {
      // When using the native app, only load the routes
      // for the native app
      if (device.isNativeApp && !route.forConferenceApp) {
        return null; // Skip rendering this route
      }

      const IconComponent = route.iconName
        ? hubIconMapper[route.iconName]
        : undefined;

      return route.children && route.children.length ? (
        <MenuItemDisclosure
          key={`route-nav-${route.label}`}
          label={route.label}
          initialIsOpen={false}
          buttonClassName="sidebar-menu-selector flex flex-row w-full rounded-lg items-center text-start group"
          className="pl-2"
          chevronIconClassname="w-2 h-2"
          IconComponent={IconComponent}
        >
          <MenuItemLink formattedRoute={route} overrideLabel="Main" />
          {renderMenuItems(route.children)}
        </MenuItemDisclosure>
      ) : (
        <MenuItemLink
          key={`route-nav-${route.label}`}
          formattedRoute={route}
          testId={`main-menu-${route.label}`}
        />
      );
    });
  };

  const userRoutes = session.society!.routes!.filter((route) => {
    if (!route.checkPermissionTags && route.displayInNav) {
      return true;
    }

    return route.tags.find((tag) =>
      session.profile?.tags?.some(
        (userTag: SocietyTagView) =>
          userTag.description === tag.description && route.displayInNav,
      ),
    );
  });

  // Sort the filtered userRoutes based on the "navMenuOrderNum" property
  userRoutes.sort((a, b) => a.navMenuOrderNum - b.navMenuOrderNum);

  return (
    <IonMenu
      type="overlay"
      contentId="main-content"
      disabled={!session.societyUserId}
    >
      <IonContent className="border-r border-neutral-mid-100 ios-side-menu-wrapper">
        <div className="flex flex-col h-full justify-between">
          <div>
            <AdminImpersonateInfo />
            <IonList>
              <div className="flex ml-3.5 items-center">
                <SocietyLogo className="h-9.5 w-9.5 mb-2" />
                <h2 className="mb-1.5 ml-2 text-title">
                  {session.society?.abbreviation}
                </h2>
              </div>
              {renderMenuItems(userRoutes)}
            </IonList>
          </div>
          <div key="special-action-bank" className="menu-action-bank">
            <div className="menu-section-border" />
            {location.pathname.includes("conference") && (
              <div className="w-full ml-1.5">
                <select
                  onChange={(e) => {
                    session.setTimezone(e.target.value);
                  }}
                >
                  <option value="system">Local Time</option>
                  <option value="conference">Conference Venue Time</option>
                </select>{" "}
              </div>
            )}
            <NewAppButton
              className="menu-action-bank-button"
              onClick={() => session.logOut()}
            >
              <LogOutOutlineIcon className="menu-action-bank-icon ion-like-icon" />
              Sign Out
            </NewAppButton>
          </div>
          <div key="special-action-close" className="fixed bottom-2 right-2">
            <IonMenuToggle>
              <NewAppButton className="menu-button-close h-3 w-3">
                <ChevronBackIcon className="ion-like-icon" />
              </NewAppButton>
            </IonMenuToggle>
          </div>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default MainMenu;
