/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import {
  NodeViewItem,
  PreviewView,
} from "admin/src/server/mappers/submission/preview/preview-instance";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";

import { ReviewInstanceAssignmentView } from "admin/src/server/mappers/review/reviewInstanceAssignment";
import MustacheTemplateComponent from "admin/src/ui/components/MustacheTemplateComponent";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { processDisplayValue } from "hub/src/utils/reviews/processDisplayValue";
import { getProfileId } from "hub/src/utils/sessionStorage/user";
import { getSocietyProfileReviewAssignmentSubmissionInstancePreviewContract } from "shared/api-contracts/society/societyId/profiles/profileId/review/assignments/reviewAssignmentId/submission/submissionInstanceId/preview";

//Check with Gary on this
const excludedQuestionTypes = ["profilesearchmultiple", "products"];

type SubmissionInstanceTabProps = {
  submissionDefinitionId?: number;
  submissionInstanceId?: number;
  reviewInstanceAssignment?: ReviewInstanceAssignmentView;
  disclosureInstanceId?: number;
  disclosureDefinitionId?: number;
  profileId: number;
};

const SubmissionInstanceTab = ({
  submissionDefinitionId,
  submissionInstanceId,
  reviewInstanceAssignment,
  disclosureInstanceId,
  disclosureDefinitionId,
  profileId,
}: SubmissionInstanceTabProps) => {
  const [instancePreview, setInstancePreview] = useState<PreviewView | null>(
    null,
  );
  const [disclosurePreview, setDisclosurePreview] =
    useState<PreviewView | null>(null);

  useEffect(() => {
    if (submissionInstanceId && submissionDefinitionId) {
      newApiRequest(
        getSocietyProfileReviewAssignmentSubmissionInstancePreviewContract,
        {
          params: {
            societyId: getSocietyId(),
            reviewAssignmentId: Number(
              reviewInstanceAssignment?.reviewInstanceAssignmentId,
            ),
            submissionInstanceId: submissionInstanceId,
            profileId: getProfileId(),
          },
        },
      ).then((response) => {
        setInstancePreview(response);
      });
    }
    if (disclosureInstanceId && disclosureDefinitionId) {
      newApiRequest(
        getSocietyProfileReviewAssignmentSubmissionInstancePreviewContract,
        {
          params: {
            societyId: getSocietyId(),
            reviewAssignmentId: Number(
              reviewInstanceAssignment?.reviewInstanceAssignmentId,
            ),
            submissionInstanceId: disclosureInstanceId,
            profileId: getProfileId(),
          },
        },
      ).then((response) => {
        setDisclosurePreview(response);
      });
    }
  }, []);

  const processPreviewData = (previewData: PreviewView | null) => ({
    ...previewData,
    body: previewData?.body
      .filter(
        (item: NodeViewItem) =>
          !excludedQuestionTypes.includes(item.questionType),
      )
      .map((item: NodeViewItem) => ({
        ...item,
        titleHtml: item.title,
        ...processDisplayValue(item.displayValue, item.questionType),
      })),
  });

  const reduceParsedData = (templateData: any) =>
    templateData.body?.reduce(
      (acc: any, templateBodyItem: { name: string | number }) => {
        acc[templateBodyItem.name] = templateBodyItem;
        return acc;
      },
      { ...templateData },
    );

  const instancePreviewTemplateData = processPreviewData(instancePreview);
  const disclosurePreviewTemplateData = processPreviewData(disclosurePreview);
  const submissionDataAfterParse = {
    ...reduceParsedData(instancePreviewTemplateData),
    submission_instance_id: submissionInstanceId,
  };
  const disclosureDataAfterParse = {
    ...reduceParsedData(disclosurePreviewTemplateData),
    disclosure_instance_id: disclosureInstanceId,
  };

  return (
    <>
      {instancePreview && (
        <MustacheTemplateComponent
          className="pb-2"
          template={
            reviewInstanceAssignment?.session?.submissionTemplate ??
            "No Template Found"
          }
          data={submissionDataAfterParse}
        />
      )}
      {disclosurePreview && (
        <MustacheTemplateComponent
          className="pt-2"
          template={
            reviewInstanceAssignment?.session?.disclosureTemplate ??
            "No Template Found"
          }
          data={disclosureDataAfterParse}
        />
      )}
    </>
  );
};
export default SubmissionInstanceTab;
