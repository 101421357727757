import { DirectoryWithProfilesView } from "admin/src/server/mappers/directory/directory";
import { ApiResponse } from "admin/src/ui/types/common/api-response";
import { PaginationRequest } from "admin/src/ui/types/common/general-types";
import { FiltersRequest } from "admin/src/utils/helpers/filter-where-clause";
import { ApiQueryConfig } from "hub/src/api/types";

const GetDirectory: ApiQueryConfig<
  ApiResponse<DirectoryWithProfilesView>,
  { societyId: string; directoryId: string },
  FiltersRequest & PaginationRequest
> = {
  apiPath: "/api/v1/society/{societyId}/directory/{directoryId}",
  queryKey: {
    baseQueryKey: ["GetDirectory"],
    dynamicQueryKey: ["societyId", "directoryId"],
  },
};

export { GetDirectory };
