import { RouteConfigView } from "admin/src/server/mappers/app-route/app-route-config/app-route-config";

export type FormattedUserRoute = RouteConfigView & {
  children?: RouteConfigView[];
};

export const formatRoutesList = (
  userRoutes: RouteConfigView[],
): FormattedUserRoute[] => {
  const rootUserRoutes = userRoutes.filter((route) => !route.parentRouteConfig);

  const childUserRoutes = userRoutes.filter(
    (route) => !!route.parentRouteConfig,
  );

  if (!rootUserRoutes.length) {
    return userRoutes;
  }

  return rootUserRoutes.map((rootRoute) => {
    const childRoutes = childUserRoutes.filter(
      (childRoute) => childRoute.parentRouteConfig?.id === rootRoute.id,
    );

    return {
      ...rootRoute,
      children: childRoutes,
    };
  });
};
