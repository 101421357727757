import { useChannel } from "@ably-labs/react-hooks";
import { GetMessages, fetchApiQuery } from "hub/src/api";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ChatMessage } from "hub/src/types/chat";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { getProfileId } from "hub/src/utils/sessionStorage/user";
import { ChatMessages } from "./ChatMessages";
import { NewMessageBox } from "./NewMessageBox";
import { ParticipantsBar } from "./ParticipantsBar";

interface RouteParams {
  id: string;
}

const UserConversation = () => {
  const messagesDivRef = useRef<HTMLDivElement>(null);

  const { id } = useParams<RouteParams>(); //ChatID
  const session = useHubSessionContext();
  const [userMessages, setUserMessages] = useState<ChatMessage[]>([]);

  const fetchUserMsgs = async () => {
    try {
      const resp = await fetchApiQuery(GetMessages, {
        societyId: getSocietyId().toString(),
        profileId: session.profileId?.toString(),
        chatId: id?.toString(),
      });

      if (resp?.data?.body) {
        setUserMessages(resp?.data?.body.messages);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (Number(id) > 0) {
      fetchUserMsgs();
    }
  }, [id]);

  // this is not working :(
  useEffect(() => {
    if (messagesDivRef.current) {
      messagesDivRef.current.scrollTop = messagesDivRef.current.scrollHeight;
    }
  }, [userMessages]);

  useChannel(
    `Society:${getSocietyId()}:Chat:ProfileId:${getProfileId()}`,
    () => {
      fetchUserMsgs();
    },
  );

  return (
    <>
      <div className="flex flex-col h-full">
        <ParticipantsBar
          chatId={Number(id)}
          showListOfUser={Number(id) === 0}
        />
        <div className="flex-auto overflow-y-auto container-page-padding bg-neutral-light-400">
          <ChatMessages chatId={Number(id)} />
        </div>
        <NewMessageBox chatId={Number(id)} />
      </div>
    </>
  );
};

export default UserConversation;
