import { configureAbly } from "@ably-labs/react-hooks";

import { useIonRouter } from "@ionic/react";

import LoadingPage from "hub/src/components/common/LoadingPage";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import {
  clearProfileId,
  getProfileId,
} from "hub/src/utils/sessionStorage/user";

import { SessionView } from "admin/src/server/mappers/session/session";
import {
  DeauthenticateUser,
  fetchApiQuery,
  GetAblyTokenCreation,
  ImpersonateProfile,
  mutateApiQuery,
  useApiQuery,
  VerifyAuthenticatedUser,
  VerifyToken,
} from "hub/src/api";
import AblyComponent from "hub/src/components/common/AblyComponent";
import {
  initializePushNotifications,
  unregisterPushNotifications,
} from "hub/src/utils/firebase/pushNotification";
import { getRedirectUrl } from "hub/src/utils/getRedirectUrl";
import { setProfileId } from "../utils/sessionStorage/user";

interface SessionContext extends SessionView {
  timezone: string;
  setTimezone: (timezone: string) => void;
  setSession: (session: SessionView | null) => void;
  logOut: () => void;
  refreshSession: () => Promise<void>;
  impersonateProfile: (profileId: number, url?: string) => void;
}

const defaultSessionView: SessionView = {
  environment: null,
  societyUser: null,
  society: null,
  profile: null,
  societyAdmin: null,
  iat: 0,
  authInvalidBefore: "",
  societyUserId: null,
  societyAdminId: null,
  adminMode: false,
  profileId: null,
  societyId: null,
  societies: [],
  profileIds: [],
  tags: [],
};
const SessionContext = createContext<SessionContext>({
  timezone: "system",
  setTimezone: () => undefined,
  setSession: () => undefined,
  logOut: () => undefined,
  refreshSession: async () => undefined,
  impersonateProfile: () => undefined,
  ...defaultSessionView,
});

type Props = {
  children: ReactNode;
};

const SessionProvider = ({ children }: Props) => {
  //Hooks
  const history = useHistory();

  //ContextState
  const [timezone, setTimezone] = useState<string>("system");

  //Session is the SessionView    object from the API
  const [session, setSession] = useState<SessionView | null>(null);

  //Look for token in URL
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tokenValue = params.get("token") ?? params.get("impersonate");
  const fileTokenValue = params.get("t");

  const { data: tokenData, isLoading: tokenIsLoading } = useApiQuery(
    VerifyToken,
    {
      societyId: getSocietyId().toString(),
      tokenValue: tokenValue,
    },
    {},
    {},
    { staleTime: -1, enabled: !!tokenValue },
  );

  const { data: fileTokenData, isFetching: fileTokenIsLoading } = useApiQuery(
    VerifyToken,
    {
      societyId: getSocietyId().toString(),
      tokenValue: fileTokenValue,
    },
    {},
    {},
    { enabled: !!fileTokenValue },
  );

  const impersonateProfile = async (profileId: number, url?: string) => {
    setSession(null);
    // console.log("started impersonateProfile authentcating", isAuthenticating);
    //TODO: We can speed this up by get the session from the response instead of refreshing.
    await fetchApiQuery(
      ImpersonateProfile,
      {
        societyId: getSocietyId().toString(),
        profileId: profileId.toString(),
      },
      {},
      {},
      { staleTime: -1 },
    );
    // console.log("completed impersonateProfile authentcating", profileId, url);
    await refreshSession();
    // console.log("finished impersonateProfile authentcating", isAuthenticating);
    if (url) {
      history.push(url);
    }
  };
  const router = useIonRouter();
  useEffect(() => {
    // console.log("tokenDataChanges", tokenData);
    if (tokenData) {
      if (tokenData.data.body?.usage.login) {
        const loginUsage = tokenData.data.body.usage.login;
        let url = loginUsage.routePath;
        for (const key in loginUsage.pathParam) {
          url = url.replace(`:${key}`, loginUsage.pathParam[key].toString());
        }
        impersonateProfile(loginUsage.profileId, url);
        // session.societyUserId = loginUsage.societyUserId;
        // session.impersonateProfile(loginUsage.profileId, url);
      } else {
        throw new Error(
          "Token is not a login token only login tokens supported",
        );
      }
    }
  }, [tokenData]);
  const setSocietyTheme = (theme: any) => {
    theme.forEach((theme: any) => {
      document.documentElement.style.setProperty(
        `--${theme.property}`,
        theme.value,
      );
    });
  };

  useEffect(() => {
    const currentProfileId = getProfileId();
    if (
      typeof session?.profileId === "number" &&
      session?.profileId !== currentProfileId
    ) {
      setProfileId(session?.profileId!);
    }
  }, [session?.profileId]);

  useEffect(() => {
    if (session?.societyUserId && fileTokenValue && fileTokenData) {
      history.push(
        `/shared-urls/${fileTokenData.data.body?.usage.file?.fileId}`,
      );
    }
  }, [session?.societyUserId, fileTokenData, fileTokenValue]);

  useEffect(() => {
    const { protocol, host, pathname: previousPath } = window.location;
    const currentPath = `${protocol}//${host}${previousPath}`;
    const newPath = `/login?redirectURL=${encodeURIComponent(currentPath)}`;

    if (session?.societyUserId) {
      if (
        ["/", "/login", "/account/new", "/account/recover"].includes(
          previousPath,
        )
      ) {
        if (fileTokenIsLoading) return;
        history.push("/overview");
      } else {
        history.push(getRedirectUrl(newPath)!);
      }
    }
  }, [session?.societyUserId, router.routeInfo.pathname]);

  const logOut = useCallback(async () => {
    try {
      const response = await mutateApiQuery(
        DeauthenticateUser,
        {
          societyId: getSocietyId().toString(),
          profileId: getProfileId().toString(),
        },
        {},
      );

      if (response.data) {
        await unregisterPushNotifications();
      }
    } catch (error) {
      console.error(error);
    } finally {
      clearProfileId();
      setSession(null);
      history.push("/login");
    }
  }, [history]);
  const refreshSession = async () => {
    const tokenCondition =
      (tokenValue && !tokenIsLoading) || tokenValue === null;

    const fileTokenCondition = fileTokenValue && !fileTokenIsLoading;

    if (tokenCondition || fileTokenCondition) {
      const initialSessionDetails = await fetchApiQuery(
        VerifyAuthenticatedUser,
        {
          societyId: getSocietyId().toString(),
        },
        {},
        {},
        {
          staleTime: -1,
        },
      );
      setSession(initialSessionDetails.data.body!);
    }
  };

  //Watch Session, sometimes we set it to Null and need to refresh it.
  useEffect(() => {
    // console.log("session.societyUserId", session?.societyUserId);
    if (!session?.societyUserId) {
      refreshSession();
    } else {
      if (session.society && session.society.theme) {
        setSocietyTheme(session.society.theme);
      }
    }
  }, [session?.societyUserId]);

  //Ably
  const [ablyReady, setAblyReady] = useState<boolean>(false);
  useEffect(() => {
    const ably = configureAbly({
      autoConnect: false,
      authCallback: async (data, callback) => {
        try {
          const response = await fetchApiQuery(
            GetAblyTokenCreation,
            { societyId: getSocietyId().toString() },
            {},
          );
          callback(null, response.data.body!);
          setAblyReady(true);
        } catch (error) {
          // console.error(error);
          // throw new Error("Unable to get Ably's Token.");
        }
      },
    });

    if (
      session?.societyUserId &&
      session?.society?.societySettingsPublic?.ablyEnabled &&
      !ablyReady
    ) {
      initializePushNotifications();
      ably.connect();
    }

    // We want to clean up our connections,  but also this is breaking things so
    // TODO: Fix this ~ GS and Silvestre 7/21/23 1:22PM
    // return () => {
    //   console.log("unmounting");
    //   //      ably.close();
    // };
  }, [session?.societyUserId, session?.society, ablyReady]);
  //The juice of the context
  const value = useMemo(
    () => ({
      timezone,
      setTimezone,
      setSession,
      logOut,
      impersonateProfile,
      refreshSession,
      environment: session?.environment ?? null,
      societyUser: session?.societyUser ?? null,
      society: session?.society ?? null,
      profile: session?.profile ?? null,
      societyAdmin: session?.societyAdmin ?? null,
      iat: session?.iat ?? 0,
      authInvalidBefore: session?.authInvalidBefore ?? "",
      societyUserId: session?.societyUserId ?? null,
      societyAdminId: session?.societyAdminId ?? null,
      adminMode: session?.adminMode ?? false,
      profileId: session?.profileId ?? null,
      societyId: session?.societyId ?? null,
      societies: session?.societies ?? [],
      profileIds: session?.profileIds ?? [],
      tags: session?.tags ?? [],
    }),
    [
      timezone,
      setTimezone,
      logOut,
      impersonateProfile,
      refreshSession,
      tokenIsLoading,
      fileTokenIsLoading,
      session?.societyUserId,
      session?.profileId,
      session?.societyAdminId,
      session,
    ],
  );

  if (!session?.societyId || fileTokenIsLoading) {
    return <LoadingPage />;
  }

  if (ablyReady) {
    return (
      <SessionContext.Provider value={value}>
        <AblyComponent>{children}</AblyComponent>
      </SessionContext.Provider>
    );
  }
  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
