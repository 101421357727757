import { Participant } from "hub/src/types/chat";

export const getParticipantInfo = (participant: Participant) => {
  if (participant.profile) {
    return {
      firstName: participant.profile.societyUser?.firstName,
      lastName: participant.profile.societyUser?.lastName,
      pictureUrl: participant.profile.picture,
    };
  }

  if (participant.adminUser) {
    const [firstName, lastName] = participant.adminUser.name.split(" ");
    return { firstName, lastName, pictureUrl: "" };
  }

  return { firstName: "", lastName: "", pictureUrl: "" };
};
