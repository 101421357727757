import { PaginatedResults } from "admin/src/server/mappers/Pagination";
import { InstitutionRosterMemberView } from "admin/src/server/mappers/profile/institution-societyuser-relation";
import { ApiResponse } from "admin/src/ui/types/common/api-response";
import { ApiMutationConfig, ApiQueryConfig } from "hub/src/api/types";

const GetRoster: ApiQueryConfig<
  ApiResponse<PaginatedResults<InstitutionRosterMemberView>>,
  { societyId: string; profileId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/profiles/{profileId}/institution/roster",
  queryKey: { baseQueryKey: ["GetRoster"], dynamicQueryKey: ["profileId"] },
};

const AddNewUserToRoster: ApiMutationConfig<
  ApiResponse<void>,
  { societyId: string },
  {
    id: number;
    institution_name: string;
    first_name: string;
    last_name: string;
    email: string;
    society_id: number;
  }
> = {
  method: "POST",
  apiPath: "/api/v1/society/{societyId}/profiles/institution",
  invalidates: [
    {
      baseQueryKey: GetRoster.queryKey.baseQueryKey,
      dynamicQueryKey: ["id"],
    },
  ],
};

const AddExistingUserToRoster: ApiMutationConfig<
  ApiResponse<void>,
  { societyId: string },
  {
    id: number;
    societyId: number;
    institutionId: number;
    display_name: string;
    institution_name: string;
    email: string;
  }
> = {
  method: "POST",
  apiPath: "/api/v1/society/{societyId}/profiles/institution/existing",
  invalidates: [
    {
      baseQueryKey: GetRoster.queryKey.baseQueryKey,
      dynamicQueryKey: ["institutionId"],
    },
  ],
};

const DeleteRosterMember: ApiMutationConfig<
  ApiResponse<void>,
  { societyId: string; profileId: string },
  { insitutionRelationId: number }
> = {
  method: "DELETE",
  apiPath:
    "/api/v1/society/{societyId}/profiles/{profileId}/institution/roster",
  invalidates: [
    {
      baseQueryKey: GetRoster.queryKey.baseQueryKey,
      dynamicQueryKey: ["profileId"],
      exact: false,
    },
  ],
};

export {
  AddExistingUserToRoster,
  AddNewUserToRoster,
  DeleteRosterMember,
  GetRoster,
};
