// TODO: We might be able to delete this function and use interpolateApiPath
// Adding a todo to see if we can refactor in the future.

export const mapPathParamsToUrl = (
  url: string,
  pathParams: Record<string, string | number>,
): string => {
  if (!pathParams || Object.keys(pathParams).length === 0) {
    return url;
  }

  let mappedUrl = url;

  Object.entries(pathParams).forEach(([key, value]) => {
    mappedUrl = mappedUrl.replace(`:${key}`, value.toString());
  });

  return mappedUrl;
};
