import { GetMessages, useApiQuery } from "hub/src/api";
import { updateUserHasReadMsg } from "hub/src/api/chatAPI";
import LoadingPage from "hub/src/components/common/LoadingPage";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { useEffect, useRef } from "react";
import { Participant, ReadMessage } from "hub/src/types/chat";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { getProfileId } from "hub/src/utils/sessionStorage/user";
import MessageComponent from "./MessageComponent";

type Props = {
  chatId: number;
};

export const ChatMessages = ({ chatId }: Props) => {
  const session = useHubSessionContext();
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const { data: messagesResponse, isLoading: loadingMsgData } = useApiQuery(
    GetMessages,
    {
      societyId: getSocietyId().toString(),
      profileId: session.profileId?.toString(),
      chatId: chatId?.toString(),
    },
    {},
    {},
    {
      enabled: !!chatId,
    },
  );

  const isCurrentUser = (participant: Participant): boolean => {
    const profile = participant.profile;

    if (profile) {
      return profile.id === getProfileId();
    }

    return false;
  };

  const userMessages = messagesResponse?.data?.body?.messages ?? [];

  useEffect(() => {
    const updateUnreadMessages = async () => {
      const unReadMsgs = userMessages.reduce<ReadMessage[]>((result, msg) => {
        if (!msg.isSeen) {
          result.push({
            isAdmin: false,
            messageId: msg.id,
            chatId: chatId,
            profileId: getProfileId(),
          });
        }
        return result;
      }, []);

      if (unReadMsgs?.length) {
        try {
          await updateUserHasReadMsg(unReadMsgs);
        } catch (error) {
          console.error("Error updating unread messages:", error);
        }
      }
    };

    if (userMessages.length) {
      updateUnreadMessages();
    }
  }, [userMessages]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [userMessages]);

  if (loadingMsgData && chatId > 0) {
    return <LoadingPage />;
  }

  return (
    <>
      {userMessages.map((msg, index) => (
        <div
          key={msg.id}
          ref={index === userMessages.length - 1 ? lastMessageRef : null}
        >
          <MessageComponent
            message={msg.content}
            isCurrentUser={isCurrentUser(msg.chatSenderParticipant)}
            participant={msg.chatSenderParticipant}
          />
        </div>
      ))}
    </>
  );
};
