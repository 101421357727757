import { useState, ComponentPropsWithoutRef } from "react";

import { splitFullName } from "admin/src/utils/helpers/profile/splitFullName";
import { generateInitials } from "admin/src/utils/helpers/profile/generateInitials";

type ProfileAvatarProps = ComponentPropsWithoutRef<"div"> & {
  fullName?: string;
  src?: string | null;
};

const ProfileAvatar = ({
  fullName,
  src,
  className,
  ...props
}: ProfileAvatarProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const getInitials = () => {
    const { firstName, lastName } = splitFullName(fullName);
    return generateInitials(firstName, lastName);
  };

  return (
    <>
      {src ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className={`profile-avatar-container ${className ?? "h-6 w-6"} ${
            isLoading ? "loading" : ""
          }`}
          src={src as string}
          alt="Profile Avatar"
          onLoad={() => setIsLoading(false)}
          {...props}
        />
      ) : (
        <div
          className={`profile-avatar-container ${className ?? "h-6 w-6"}`}
          {...props}
        >
          {getInitials()}
        </div>
      )}
    </>
  );
};
export default ProfileAvatar;
