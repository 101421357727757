import UserConversation from "hub/src/components/chat/UserConversation";
import PageComponent from "hub/src/components/common/PageComponent";

const ChatPage = () => {
  return (
    <PageComponent>
      <PageComponent.Header>
        <></>
      </PageComponent.Header>
      <PageComponent.Content>
        <UserConversation />
      </PageComponent.Content>
    </PageComponent>
  );
};

export default ChatPage;
