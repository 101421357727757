import { SendChatMsg, useApiMutation } from "hub/src/api";
import { MessageInput } from "hub/src/components/common/messages";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { getProfileId } from "hub/src/utils/sessionStorage/user";

type Props = {
  chatId: number;
};

export const NewMessageBox = ({ chatId }: Props) => {
  const { mutateAsync: sendMsgMutation, isLoading } = useApiMutation(
    SendChatMsg,
    {
      societyId: getSocietyId()?.toString(),
    },
  );

  const handleSend = async (msg: string) => {
    await sendMsgMutation({
      id: 0,
      societyId: getSocietyId(),
      content: msg,
      senderProfileId: getProfileId(),
      chat_id: chatId,
    });
  };

  return (
    <div className="flex-none p-4 h-16 border-t-2 shadow-lg border-neutral-mid-100">
      <MessageInput
        onSubmit={handleSend}
        isLoading={isLoading}
        className="resize-none"
        disabled={chatId <= 0}
      />
    </div>
  );
};
