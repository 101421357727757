/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Serializer,
} from "survey-core";
import { SurveyCreatorModel } from "survey-creator-core";
export type DegreeQuestionValues = {
  degreeType?: string;
  ["degreeType-Comment"]?: string;
  graduationDate?: string;
  university?: string;
  ["university-Comment"]?: string;
};
export const custom_question_degree = "degree_picker";
export const degree_question = async (creator?: SurveyCreatorModel) => {
  creator?.onQuestionAdded.add((sender: SurveyCreatorModel, options) => {
    if (options.question.getType() === custom_question_degree) {
      options.question.name = "degree";
    }
  });

  const questionTitle = "Degree Picker";

  const degree_question_object: ICustomQuestionTypeConfiguration = {
    name: custom_question_degree,
    title: questionTitle,
    questionJSON: {
      type: "paneldynamic",
      name: "degree_picker",
      title: "Degree Picker",
      titleLocation: "hidden",
      templateElements: [
        {
          type: "pillar_choice_dropdown",
          name: "degreeType",
          title: "Degree",
          titleLocation: "top",
          pillar_choices_question_name: "degree",
        },
        {
          type: "pillar_choice_dropdown",
          name: "university",
          title: "University",
          titleLocation: "top",
          pillar_choices_question_name: "university",
          startWithNewLine: false,
        },
        {
          title: "Graduation Date",
          titleLocation: "top",
          name: "graduationDate",
          startWithNewLine: false,
          type: "text",
          inputType: "date",
        },
      ],
    },

    onLoaded: function (): void {
      Serializer.getProperty(custom_question_degree, "name").readOnly = true;
    },
  };

  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      custom_question_degree,
    )
  ) {
    ComponentCollection.Instance.add(degree_question_object);
  }
};
