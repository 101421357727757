import { RouteConfigView } from "admin/src/server/mappers/app-route/app-route-config/app-route-config";
import { CustomTableRouterContext } from "admin/src/ui/context/CustomTableRouterContext";
import { useCustomClientTableRouter } from "admin/src/ui/hooks/useCustomClientTableRouter";
import ProtectedRoute from "hub/src/components/auth/ProtectedRoute";
import LoadingPage from "hub/src/components/common/LoadingPage";
import useDeviceInfo from "hub/src/hooks/useDeviceInfo";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import AccessDeniedPage from "hub/src/pages/AccessDeniedPage";
import AccountRecoverPage from "hub/src/pages/AccountRecoverPage";
import ChatPage from "hub/src/pages/chat/ChatPage";
import CheckoutPage from "hub/src/pages/CheckoutPage";
import FormPage from "hub/src/pages/FormPage";
import LoginPage from "hub/src/pages/LoginPage";
import NativeAppPDFViewerPage from "hub/src/pages/NativeAppPDFViewerPage";
import NewProfilePage from "hub/src/pages/NewProfilePage";
import PageNotFound from "hub/src/pages/PageNotFound";
import ReviewsVotingPage from "hub/src/pages/reviews/ReviewsVotingPage";
import SharedUrlsPage from "hub/src/pages/SharedUrlsPage";
import NewWorkflowPage from "hub/src/pages/workflow/new/complexWorkflowId";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useParams } from "react-router";
import SubmissionInstancePage from "../pages/SubmissionInstancePage";

export interface CommonRouteComponentProps {
  routeConfig: RouteConfigView;
}

const conferencePageMapper: Record<
  string,
  React.ComponentType<CommonRouteComponentProps>
> = {
  conferenceLandingPage: lazy(
    () => import("hub/src/pages/conference/ConferenceLandingPage"),
  ),
  conferenceRegistrationPage: lazy(
    () => import("hub/src/pages/conference/ConferenceRegistrationPage"),
  ),
  conferenceSchedulePage: lazy(
    () => import("hub/src/pages/conference/ConferenceSchedulePage"),
  ),
};
// Lazy loading the dynamic routes
const pageComponentMapper: Record<
  string,
  React.ComponentType<CommonRouteComponentProps>
> = {
  overviewPage: lazy(() => import("hub/src/pages/OverviewPage")),
  myGroupsPage: lazy(() => import("hub/src/pages/MyGroupsPage")),
  educationPage: lazy(() => import("hub/src/pages/EducationPage")),
  financePage: lazy(() => import("hub/src/pages/finance/FinancePage")),
  invoicePage: lazy(() => import("hub/src/pages/finance/InvoicePage")),
  communicationPage: lazy(() => import("hub/src/pages/CommunicationPage")),
  myFilesPage: lazy(() => import("hub/src/pages/MyFilesPage")),
  chatsPage: lazy(() => import("hub/src/pages/chat/ChatsPage")),
  directoriesPage: lazy(() => import("hub/src/pages/DirectoriesPage")),
  editUserDetailPage: lazy(
    () => import("hub/src/pages/user/EdituserDetailPage"),
  ),
  rosterPage: lazy(() => import("hub/src/pages/RosterPage")),
  decisionMeetingPage: lazy(() => import("hub/src/pages/DecisionMeetingPage")),
  sharedUrlsPage: lazy(() => import("hub/src/pages/SharedUrlsPage")),
  ssoPage: lazy(() => import("hub/src/pages/sso/SSOPage")),
  submissionsPage: lazy(
    () => import("hub/src/pages/submissions/SubmissionsPage"),
  ),
  reviewsPage: lazy(() => import("hub/src/pages/reviews/ReviewsPage")),
  reportsPage: lazy(() => import("hub/src/pages/reports/ReportsPage")),
  reportPage: lazy(() => import("hub/src/pages/reports/ReportPage")),
  ...conferencePageMapper,
};

const UserHubRoutes = () => {
  const session = useHubSessionContext();
  const device = useDeviceInfo();

  const router = useCustomClientTableRouter();

  if (session.society === null) {
    return <LoadingPage />;
  }

  if (session.societyAdminId !== null && session.societyUserId === null) {
    return (
      <>
        You are currently logged into admin at this same address. Please use
        incognito or the impersonation feature to access hub.
      </>
    );
  }

  return (
    <CustomTableRouterContext.Provider value={router}>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          {!session.societyUserId && (
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
          )}
          <Route exact path="/access-denied">
            <AccessDeniedPage />
          </Route>

          {/* Sorting here to ensure paths with dynamic params are rendered last. Paths match top down, so we want to go from specific to broad in order. */}
          {session
            .society!.routes!.slice()
            .sort((a) => {
              if (Object.keys(a.pathParam).length === 0) {
                return -1;
              } else {
                return 1;
              }
            })
            .map((route) => {
              if (!route.externalRoute) {
                const Component = pageComponentMapper[route.componentName];
                const isPublic = route.isPublic;

                if (isPublic) {
                  return (
                    <Route
                      exact
                      path={route.url}
                      render={() => {
                        return <Component routeConfig={route} />;
                      }}
                      key={route.id}
                    />
                  );
                } else {
                  return (
                    <ProtectedRoute
                      exact
                      path={route.url}
                      key={route.id}
                      checkPermission={route.checkPermissionTags}
                      permissionTags={route.tags}
                      render={() => {
                        return <Component routeConfig={route} />;
                      }}
                    />
                  );
                }
              }
            })}

          {session.society?.societySettingsPublic?.ablyEnabled && (
            <ProtectedRoute exact path="/chats/:id">
              <ChatPage />
            </ProtectedRoute>
          )}
          <ProtectedRoute exact path="/form/:id">
            <FormPage />
          </ProtectedRoute>
          <ProtectedRoute exact path="/shared-urls/:fileId">
            <SharedUrlsPage />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/submission/:submissionDefinitionId/instance/:submissionInstanceId"
          >
            <SubmissionInstancePage />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/reviews/:reviewSessionId/assignment/:reviewInstanceAssignmentId"
          >
            <ReviewsVotingPage />
          </ProtectedRoute>

          {device.isWeb && (
            <ProtectedRoute exact path="/payment/:invoiceId">
              <CheckoutPage />
            </ProtectedRoute>
          )}

          <ProtectedRoute exact path="/workflow/new/:workflowId">
            <NewWorkflowPage />
          </ProtectedRoute>
          <Route exact path="/account/new">
            <NewProfilePage />
          </Route>
          <Route exact path="/account/recover">
            <AccountRecoverPage />
          </Route>

          {/* I intentionally added this route here since this will be a system route and not society-specific */}
          <Route exact path="/pdf-viewer/:pdfData">
            <NativeAppPDFViewerPage />
          </Route>
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      </Suspense>
    </CustomTableRouterContext.Provider>
  );
};

export default UserHubRoutes;
