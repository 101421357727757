import {
  ProfileDto,
  ProfileView,
} from "admin/src/server/mappers/profile/profile";

export const getDisplayNameHelper = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  profile: ProfileView | ProfileDto | any,
  includeAffiliation?: boolean,
): string => {
  if (!includeAffiliation) {
    includeAffiliation = false;
  }
  //This is gross apparently the directory doesnt use the profile view so this was done instead of fixing the directory
  if (
    profile?.SocietyUser?.first_name &&
    profile?.SocietyUser?.last_name &&
    includeAffiliation &&
    profile?.SocietyUser?.affiliation
  ) {
    return `${profile?.SocietyUser.first_name} ${profile?.SocietyUser.last_name} (${profile?.SocietyUser.affiliation})`;
  }
  if (profile?.SocietyUser?.first_name && profile?.SocietyUser?.last_name) {
    return `${profile?.SocietyUser.first_name} ${profile?.SocietyUser.last_name}`;
  }
  if (
    profile?.societyUser?.firstName &&
    profile?.societyUser?.lastName &&
    includeAffiliation &&
    profile?.societyUser?.affiliation
  ) {
    return `${profile?.societyUser.firstName} ${profile?.societyUser.lastName} (${profile?.societyUser.affiliation})`;
  }
  if (
    profile?.societyUser?.displayName &&
    profile?.societyUser?.displayName !== ""
  ) {
    return profile?.societyUser?.displayName;
  }
  if (profile?.societyUser?.firstName && profile?.societyUser?.lastName) {
    return `${profile?.societyUser.firstName} ${profile?.societyUser.lastName}`;
  }
  if (profile?.institution?.name) {
    return profile?.institution?.name;
  }

  if (
    profile?.additionalInformation?.first_name &&
    profile?.additionalInformation?.last_name
  ) {
    return `${profile?.additionalInformation.first_name} ${profile?.additionalInformation.last_name}`;
  }

  return "UNKNOWN";
};
