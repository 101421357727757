import { ChoicesQuestionNameParamsInput } from "admin/src/pages/api/v1/society/[societyId]/choices/[questionName]";
import { PaginatedResults } from "admin/src/server/mappers/Pagination";
import { SocietyCustomChoiceDto } from "admin/src/server/mappers/society-custom-choices/societyCustomChoice";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface GetSocietyChoicesQuestionName extends BaseApiContract {
  method: HttpMethod.GET;
  apiRoute: string;
  paramsType: ChoicesQuestionNameParamsInput;
  responseType: PaginatedResults<SocietyCustomChoiceDto>;
}

export const getSocietyChoicesQuestionNameContract: GetSocietyChoicesQuestionName =
  {
    method: HttpMethod.GET,
    apiRoute: "/api/v1/society/[societyId]/choices/[questionName]",
    paramsType: undefined as unknown as ChoicesQuestionNameParamsInput,
    responseType:
      undefined as unknown as PaginatedResults<SocietyCustomChoiceDto>,
  };
