import NewAppCheckbox from "admin/src/ui/components/common/newform/NewAppCheckbox";
import NewAppCombobox from "admin/src/ui/components/common/newform/NewAppCombobox";
import NewAppNumberInput from "admin/src/ui/components/common/newform/NewAppNumberInput";
import NewAppSelectMenu from "admin/src/ui/components/common/newform/NewAppSelectMenu";
import NewAppSwitch from "admin/src/ui/components/common/newform/NewAppSwitch";
import NewAppTextInput from "admin/src/ui/components/common/newform/NewAppTextInput";
import NewAppUSDInput from "admin/src/ui/components/common/newform/NewAppUSDInput";
import { flattenChildComponents } from "admin/src/utils/helpers/flatten-child-components";
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import React, { ComponentPropsWithoutRef, useEffect, useState } from "react";
import * as yup from "yup";
import { AnyObject as yupAnyObject } from "yup/lib/types";
import NewAppTextAreaInput from "./NewAppTextAreaInput";

type NewAppFormProps<GenericFormikValues> = ComponentPropsWithoutRef<"form"> & {
  children: React.ReactNode;
  handleSubmit: (
    values: GenericFormikValues,
    formikHelpers?: FormikHelpers<GenericFormikValues>,
  ) => void;
  handleChanged?: (values: GenericFormikValues) => void;
  overrideInitialValues?: Partial<GenericFormikValues> | null;
  yupValidation?: yupAnyObject;
};

const NewAppFormToBeForwarded = <GenericFormikValues extends FormikValues>(
  {
    children,
    handleSubmit,
    handleChanged,
    overrideInitialValues,
    yupValidation,
    ...props
  }: NewAppFormProps<GenericFormikValues>,
  ref: React.ForwardedRef<FormikProps<GenericFormikValues> | undefined>,
) => {
  const [formValues, setFormValues] = useState<
    GenericFormikValues | undefined
  >();

  useEffect(() => {
    if (!formValues || !handleChanged) return;
    handleChanged(formValues);
  }, [formValues]);

  useEffect(() => {
    if (overrideInitialValues) {
      setFormValues(overrideInitialValues as GenericFormikValues);
    }
  }, [overrideInitialValues]);

  const initialValues: GenericFormikValues = (overrideInitialValues ??
    {}) as GenericFormikValues;
  const flattenedChildren = flattenChildComponents(children);
  const yupSchema = yupValidation ?? yup.object({});

  flattenedChildren.forEach((child) => {
    if (!overrideInitialValues) {
      if (
        child.type === NewAppTextInput ||
        child.type === NewAppTextAreaInput ||
        child.type === NewAppNumberInput ||
        child.type === NewAppUSDInput
      ) {
        initialValues[child.props.name as keyof GenericFormikValues] =
          child.props.initalValue ?? "";
      }
      if (child.type === NewAppSwitch || child.type === NewAppCheckbox) {
        initialValues[child.props.name as keyof GenericFormikValues] =
          child.props.initalValue ?? false;
      }
      if (child.type === NewAppCombobox || child.type === NewAppSelectMenu) {
        initialValues[child.props.name as keyof GenericFormikValues] =
          child.props.defaultOption?.value ?? undefined;
      }
    }
  });

  // Custom validate function to log validation errors
  const validate = (values: GenericFormikValues) => {
    try {
      // Use Yup schema to validate the form values
      yupSchema.validateSync(values, { abortEarly: false });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        // Log errors in development mode
        if (process.env.NODE_ENV === "development") {
          console.log("Yup validation errors:", error.inner);
        }

        // Transform Yup errors to Formik-compatible error object
        const formikErrors = error.inner.reduce(
          (acc: { [key: string]: string }, err) => {
            if (err.path) {
              acc[err.path] = err.message;
            }
            return acc;
          },
          {},
        );
        return formikErrors;
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(
        values: GenericFormikValues,
        formikHelpers: FormikHelpers<GenericFormikValues>,
      ) => {
        handleSubmit && handleSubmit(values, formikHelpers);
      }}
      enableReinitialize={true}
      innerRef={(formikActions) => {
        if (formikActions) {
          setFormValues(formikActions.values);
        }
        if (ref) {
          if (typeof ref === "function") {
            ref(formikActions);
          } else {
            ref.current = formikActions;
          }
        }
      }}
      validationSchema={yupSchema}
      validate={validate} // Add the custom validate function
    >
      <Form {...props}>{children}</Form>
    </Formik>
  );
};

const NewAppForm = React.forwardRef(NewAppFormToBeForwarded) as <
  GenericFormikValues extends FormikValues,
>(
  props: NewAppFormProps<GenericFormikValues> & {
    ref?: React.ForwardedRef<FormikProps<GenericFormikValues>>;
  },
) => ReturnType<typeof NewAppFormToBeForwarded>;

export default NewAppForm;
