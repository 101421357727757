export const processDisplayValue = (
  displayValue: any,
  questionType: string,
) => {
  const isCategory = (type: string) =>
    type === "primary_category" || type === "secondary_category";

  if (isCategory(questionType)) {
    return {
      isObjectDisplay: false,
      objectDisplayValue: [],
      isArrayDisplay: false,
      arrayDisplayValue: [],
      displayValue:
        displayValue[
          questionType === "primary_category"
            ? "primary_category"
            : "secondary_category"
        ] || displayValue,
    };
  }

  if (questionType === "paneldynamic" && Array.isArray(displayValue)) {
    const arrayDisplayValue = displayValue.map((item) =>
      typeof item === "object"
        ? { value: Object.values(item).join(", "), isImage: false }
        : item,
    );

    return {
      isObjectDisplay: false,
      objectDisplayValue: [],
      isArrayDisplay: true,
      arrayDisplayValue,
    };
  }

  if (Array.isArray(displayValue)) {
    const arrayDisplayValue = displayValue
      .filter((value) => value !== null && value !== "")
      .map((item) =>
        typeof item === "object" && !Array.isArray(item)
          ? {
              key: item.name,
              value: item.content,
              isImage: item.type?.startsWith("image/") ?? false,
              isLink:
                item.type ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                item.type === "application/pdf",
            }
          : item,
      );

    return {
      isObjectDisplay: false,
      objectDisplayValue: [],
      isArrayDisplay: arrayDisplayValue.length > 0,
      arrayDisplayValue,
    };
  }

  if (typeof displayValue === "object" && !Array.isArray(displayValue)) {
    const objectDisplayValue = Object.keys(displayValue)
      .map((key) => ({
        key,
        value: displayValue[key],
        isImage: displayValue.type?.startsWith("image/") ?? false,
        isLink:
          displayValue.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }))
      .filter((item) => item.value !== null && item.value !== "");

    return {
      isObjectDisplay: objectDisplayValue.length > 0,
      objectDisplayValue,
      isArrayDisplay: false,
      arrayDisplayValue: [],
    };
  }

  return {
    isObjectDisplay: false,
    objectDisplayValue: [],
    isArrayDisplay: false,
    arrayDisplayValue: [],
    displayValue,
  };
};
