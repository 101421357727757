import { Participant } from "hub/src/types/chat";
import ProfileAvatar from "admin/src/ui/components/common/ProfileAvatar";
import { getDisplayNameHelper } from "admin/src/utils/helpers/profile/getDisplayNameHelper";

type Props = {
  message: string;
  isCurrentUser: boolean;
  participant: Participant;
};

const MessageComponent = ({ message, isCurrentUser, participant }: Props) => {
  const fullName = participant.profile
    ? getDisplayNameHelper(participant.profile)
    : participant.adminUser?.name;

  return (
    <div className="flex mb-4">
      <div
        className={`w-full flex gap-2.5 justify-${isCurrentUser ? "end" : "start"}`}
      >
        <div
          className={`bg-${isCurrentUser ? "primary" : "neutral"}-100 py-2 px-2 shadow rounded-bl-2xl rounded-br-2xl 
          ${isCurrentUser ? "rounded-tl-2xl" : "order-2 rounded-tr-2xl"}`}
        >
          <p className="text-detailblock-base leading-5">{message}</p>
        </div>

        <div className="flex items-start">
          <ProfileAvatar
            src={participant.profile?.picture ?? ""}
            fullName={fullName}
          />
        </div>
      </div>
    </div>
  );
};

export default MessageComponent;
