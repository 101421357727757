import { FormView } from "admin/src/server/mappers/form/form";
import { ApiResponse } from "admin/src/ui/types/common/api-response";
import { ApiMutationConfig, ApiQueryConfig } from "hub/src/api/types";

const GetForm: ApiQueryConfig<
  ApiResponse<FormView>,
  { societyId: string; formId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/form/{formId}",
  queryKey: { baseQueryKey: ["GetForm"], dynamicQueryKey: ["formId"] },
};

//TODO: The hub should not be responsible for updating the choices anyway but this route has been removed.
const updateCustomChoice: ApiMutationConfig<
  void,
  { societyId: string; questionId: string; choiceId: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { newValue: any }
> = {
  method: "PUT",
  apiPath:
    "/api/v1/society/{societyId}/surveyJS/question/{questionId}/choice/{choiceId}",
};

//TODO: The hub should not be responsible for updating the choices anyway but this route has been removed.
const updateCustomChoices: ApiMutationConfig<
  void,
  { societyId: string; questionId: string },
  { choices: { [key: string]: string | number }[] }
> = {
  method: "PUT",
  apiPath: "/api/v1/society/{societyId}/surveyJS/question/{questionId}",
};

export { GetForm, updateCustomChoice, updateCustomChoices };
