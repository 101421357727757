import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import NewAppButton from "admin/src/ui/components/common/newform/NewAppButton";
import { deleteInvoiceItemById, getInvoicePDF } from "hub/src/api/invoiceApi";
import { NewAppModalHandle } from "hub/src/components/common/newmodal/NewAppModalBody";
import XMarkIcon from "hub/src/components/icons/XMarkIcon";
import usePDFDownload from "hub/src/hooks/usePDFDownload";
import { ComponentPropsWithoutRef, useRef, useState } from "react";
import ApplyDiscountCodeModal from "./ApplyDiscountCodeModal";

import { IonSpinner } from "@ionic/react";
import { InvoiceItemDiscountView } from "admin/src/server/mappers/accounting/discount/invoice-item-discount";
import { InvoiceItemView } from "admin/src/server/mappers/accounting/invoice/invoice-item";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import { getSocietyProfileAccountingInvoiceIdContract } from "shared/api-contracts/society/societyId/profiles/profileId/accounting/invoice/invoiceId";

type OrderSummaryProps = {
  invoiceId: number;
} & ComponentPropsWithoutRef<"div">;

const OrderSummary = ({
  className,
  invoiceId,
  ...props
}: OrderSummaryProps) => {
  const queryClient = useQueryClient();

  //   const { invoiceId } = useParams<{ invoiceId: string }>();
  const session = useHubSessionContext();

  const invoicePdf = usePDFDownload(getInvoicePDF);
  const applyDiscountCodeRef = useRef<NewAppModalHandle>(null);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState<
    InvoiceItemView | undefined
  >();

  const invoiceQuery = useQuery(
    [`invoiceId-${invoiceId}`],
    async () =>
      await newApiRequest(getSocietyProfileAccountingInvoiceIdContract, {
        params: {
          societyId: session.societyId!,
          profileId: session.profileId!,
          invoiceId: Number(invoiceId),
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const deleteInvoiceItem = useMutation({
    mutationFn: (invoiceItemId: number) => deleteInvoiceItemById(invoiceItemId),
    onSuccess: () => {
      queryClient.invalidateQueries(["invoice", invoiceId]);
    },
  });

  const openDiscountCodeModal = (invoiceItem: InvoiceItemView) => {
    setSelectedInvoiceItem(invoiceItem);
    applyDiscountCodeRef.current?.open();
  };

  return (
    <>
      {invoiceQuery?.data && selectedInvoiceItem && (
        <ApplyDiscountCodeModal
          modalRef={applyDiscountCodeRef}
          invoiceItem={selectedInvoiceItem}
          invoice={invoiceQuery?.data}
          refetchInvoice={invoiceQuery.refetch}
        />
      )}
      <div
        className={`container-light border-r border-neutral-mid-50 pt-4.5 ${
          className ?? ""
        }`}
        {...props}
      >
        <h1 className="sr-only">Order information</h1>
        <section aria-labelledby="summary-heading">
          {/* ANCHOR: Header */}
          <div className="flex justify-between">
            <div className="text-title">Order Summary</div>
            {invoicePdf.isLoading ? (
              <IonSpinner className="h-5 w-5" />
            ) : (
              <NewAppButton
                className="button-regular-general-filled w-fit whitespace-nowrap"
                onClick={() => invoicePdf.downloadPDF(Number(invoiceId))}
              >
                Print Invoice
              </NewAppButton>
            )}
          </div>
          <div className="flex flex-col space-y-2 pt-3">
            <div className="flex flex-col !max-h-30 overflow-y-auto">
              {invoiceQuery &&
                invoiceQuery?.data?.invoiceItems?.map(
                  (invoiceItem: InvoiceItemView, index) => (
                    <div
                      key={`invoice-item-${invoiceItem.id}`}
                      className={
                        "py-1 pl-1 flex justify-between rounded-lg " +
                        (index % 2 == 0
                          ? "bg-neutral-light"
                          : "bg-neutral-light-550")
                      }
                    >
                      <div className="flex flex-col space-y-0.5">
                        <p className="text-detailblock-title !capitalize">
                          {invoiceItem?.product?.name?.toLowerCase()}
                        </p>
                        <div className="flex space-x-1">
                          <div>
                            <span className="text-detailblock-base">
                              Quantity:&nbsp;
                            </span>
                            <span className="text-detailblock-subtle">
                              {invoiceItem?.quantity}
                            </span>
                          </div>
                          <div>
                            <span className="text-detailblock-base">
                              Total Price:&nbsp;
                            </span>
                            <span className="text-detailblock-subtle">
                              {invoiceItem?.total && invoiceItem.total > 0
                                ? `$${invoiceItem?.total}`
                                : "$0"}
                            </span>
                          </div>
                        </div>
                      </div>
                      {invoiceItem?.product?.type?.description ===
                      "Donation" ? (
                        <NewAppButton
                          className="button-regular-destructive-unfilled"
                          onClick={() => {
                            invoiceItem?.id &&
                              deleteInvoiceItem.mutateAsync(invoiceItem.id);
                          }}
                        >
                          <XMarkIcon className="h-2.5 w-2.5" />
                        </NewAppButton>
                      ) : null}
                      <div className="flex pt-1">
                        <div className="pt-1">
                          {invoiceItem.discount?.length ?? 0 > 0 ? (
                            <div>Promo code applied</div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <NewAppButton
                          className="button-small-neutral-filled"
                          onClick={() => openDiscountCodeModal(invoiceItem)}
                        >
                          Apply Promo Code
                        </NewAppButton>
                      </div>
                    </div>
                  ),
                )}
            </div>
            <div className="container-divider-border pb-2 flex flex-col">
              <div>
                <span className="text-detailblock-title text-neutral-dark">
                  Total:&nbsp;
                </span>
                <span className="text-detailblock-title text-neutral-dark">
                  {Number(invoiceQuery?.data?.total) > 0
                    ? `$${invoiceQuery?.data?.total}`
                    : "$0"}
                </span>
              </div>
              {invoiceQuery?.data?.invoiceItems?.some(
                (invoiceItem) => invoiceItem.discount?.length ?? 0 > 0,
              ) && (
                <div className="text-subtle">
                  Promo Codes Applied:
                  <div className="flex flex-col">
                    {invoiceQuery?.data?.invoiceItems.flatMap((invoiceItem) => {
                      return invoiceItem.discount?.flatMap(
                        (iiDc: InvoiceItemDiscountView) => {
                          return (
                            <div
                              className="
                              bg-neutral-light-600
                          rounded-md my-1 p-1
                              w-full"
                            >
                              {iiDc.discountCode?.name}
                            </div>
                          );
                        },
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OrderSummary;
