/* eslint-disable @typescript-eslint/no-explicit-any */
import { generateIteratedQuestionName } from "admin/src/ui/components/surveyJS/utils/geIteratedQuestionName";

import { SessionView } from "admin/src/server/mappers/session/session";
import { formatUsdString } from "admin/src/utils/helpers/invoice/formatUsdString";
import {
  ComponentCollection,
  ItemValue,
  PanelModel,
  Question,
  QuestionCheckboxModel,
  QuestionCompositeModel,
  QuestionPanelDynamicModel,
  QuestionRadiogroupModel,
  QuestionTextModel,
  Serializer,
} from "survey-core";
import { QuestionAddedEvent, SurveyCreatorModel } from "survey-creator-core";
export const ProductsSurveyJSQuestionName = "products";
export type ProductsSurveyJSQuestionProps = {
  session?: SessionView;
  creator?: SurveyCreatorModel;
};

export type ProductsSurveyJSQuestionProductOption = {
  value: number;
  price?: number;
  showPrice: boolean;
  userPriceOverride: boolean;
  text: string;
  visibleIf?: string;
  enableIf?: string;
};
export type ProductsSurveyJSQuestionProductValue = {
  productId: number;
  price?: number;
  invoiceId?: number;
};
export type ProductsSurveyJSQuestionValue = {
  products?: ProductsSurveyJSQuestionProductValue[];
};
enum ProductsSurveyJSQuestionProperties {
  multipleChoice = "multipleChoice",
  options = "options",
}

export const ProductsSurveyJSQuestionRegisterProperties = (
  session?: SessionView,
) => {
  Serializer.addProperty(ProductsSurveyJSQuestionName, {
    name: ProductsSurveyJSQuestionProperties.multipleChoice,
    displayName: "Multiple Choice",
    type: "boolean",
    categoryIndex: 0,
    visibleIndex: 0,
    category: "Product Question",
    default: false,
  });
  Serializer.addClass(
    "productitemvalue",
    [
      {
        name: "value",
        type: "dropdown",
        choices: session?.society?.products?.map((product) => ({
          value: product.id,
          text: product.name,
        })),
      },
      { name: "text", type: "string", showMode: "form" },
      { name: "price", type: "number", showMode: "form" },
      { name: "showPrice", type: "boolean", showMode: "form" },
      { name: "userPriceOverride", type: "boolean", showMode: "form" },
    ],
    undefined,
    "itemvalue",
  );
  Serializer.addProperty(ProductsSurveyJSQuestionName, {
    name: ProductsSurveyJSQuestionProperties.options,
    type: "productitemvalue[]",
    visible: true,
    categoryIndex: 0,
    visibleIndex: 1,
    category: "Product Question",
  });
};

export const ProductsSurveyJSQuestion = async ({
  session,
  creator,
}: ProductsSurveyJSQuestionProps) => {
  ProductsSurveyJSQuestionRegisterProperties(session);
  creator?.onQuestionAdded.add(
    (sender: SurveyCreatorModel, options: QuestionAddedEvent) => {
      if (options.question?.getType() == ProductsSurveyJSQuestionName) {
        options.question.name = generateIteratedQuestionName(
          ProductsSurveyJSQuestionName,
          sender,
        );
      }
    },
  );

  const ProductsSurveyJSQuestionDefinition = {
    name: ProductsSurveyJSQuestionName,
    title: "Products",
    onInit: async () => {
      ProductsSurveyJSQuestionRegisterProperties();
    },
    onItemValuePropertyChanged(
      question: Question,
      options: {
        obj: ItemValue;
        propertyName: string;
        name: string;
        newValue: any;
      },
    ): void {
      if (
        options.propertyName === ProductsSurveyJSQuestionProperties.options &&
        options.name === "value" &&
        options.obj["text"] == options.newValue
      ) {
        const product = session?.society?.products?.find(
          (product) => product.id === options.newValue,
        );
        options.obj["text"] = product?.name ?? "ERROR: No Product Name";
      }
      console.log("onItemValuePropertyChanged buildProductQuestionElements()");
      buildProductQuestionElements(question);
    },
    onValueChanged: (question: QuestionCompositeModel) => {
      //console.log("onValueChanged", question.value);
    },
    onValueChanging: (
      question: QuestionCompositeModel,
      name: string,
      newValue: any,
    ) => {
      if (name === "multipleChoiceProduct") {
        const selectedProducts = newValue as number[];
        const updatedValues = selectedProducts.map((productIndex) => {
          const productItemValue = question.options[productIndex - 1];
          return {
            productId: productItemValue.value,
            price: productItemValue.price,
          };
        });

        // Set the new value as an array of product details
        const dynamicPanel = <QuestionPanelDynamicModel>(
          question.contentPanel.getQuestionByName("multipleChoiceProductPanel")
        );
        if (dynamicPanel) {
          dynamicPanel.setQuestionValue(updatedValues);
        }
      } else if (name === "singleChoiceProductQuestion") {
        const productItemValue = question.options[newValue - 1];
        question.setValue("productId", productItemValue.value, true, true);
        question.setValue("price", productItemValue.price, true, true);
      } else {
        console.log("onValueChanging", question, name, newValue);
      }
      return newValue;
    },
    onAfterRender: (question: Question) => {
      // console.log("onAfterRender buildProductQuestionElements()");
      // buildProductQuestionElements(question);
    },
    onLoaded: (question: Question) => {
      console.log("onLoaded buildProductQuestionElements()");

      buildProductQuestionElements(question);
    },
    createElements: () => {
      return [];
    },
  };

  const buildProductQuestionChoiceText = (
    option: ProductsSurveyJSQuestionProductOption,
  ) => {
    const product = session?.society?.products?.find(
      (product) => product.id === option.value,
    );
    const formattedPrice = formatUsdString(option.price ?? product?.price ?? 0);
    if (option.showPrice) {
      return `${
        option.text ?? product?.name ?? "ERROR: No Product Name OR Description"
      } - ${formattedPrice}`;
    }
    return (
      option.text ?? product?.name ?? "ERROR: No Product Name OR Description"
    );
  };

  const buildProductQuestionElements = (question: Question) => {
    const panel = <PanelModel>question["contentPanel"];
    if (!panel) {
      return;
    }
    for (const element of panel.elements) {
      panel.removeElement(element);
    }
    buildProductQuestionElementsMultipleChoice(question);
    buildProductQuestionElementsSingleChoice(question);
  };

  const buildProductQuestionElementsMultipleChoice = (question: Question) => {
    const panel = <PanelModel>question["contentPanel"];
    if (!panel) {
      return;
    }

    const { multipleChoice } = question;
    const options = question.options as ProductsSurveyJSQuestionProductOption[];

    if (multipleChoice === true) {
      const multipleChoiceProductQuestion = new QuestionCheckboxModel(
        "multipleChoiceProduct",
      );
      multipleChoiceProductQuestion.title = "";
      multipleChoiceProductQuestion.titleLocation = "hidden";
      multipleChoiceProductQuestion.valueName = "multipleChoiceProduct";
      multipleChoiceProductQuestion.isEditableTemplateElement = false;
      multipleChoiceProductQuestion.isInteractiveDesignElement = false;
      multipleChoiceProductQuestion.isContentElement = true;

      const questionValue = <ProductsSurveyJSQuestionValue | undefined>(
        question.value
      );

      multipleChoiceProductQuestion.choices = options?.map(
        (option: ProductsSurveyJSQuestionProductOption, index: number) => {
          const currentInvoiceMatchingProductId = questionValue?.products?.find(
            (invoice) =>
              invoice.productId === option.value && invoice.invoiceId,
          );
          if (!session?.societyAdminId && currentInvoiceMatchingProductId) {
            multipleChoiceProductQuestion.readOnly = true;
          }
          const itemValue = new ItemValue(
            index + 1,
            buildProductQuestionChoiceText(option),
          );
          if (option.visibleIf && option.visibleIf !== "") {
            itemValue.visibleIf = option.visibleIf;
          }
          if (option.enableIf && option.enableIf !== "") {
            itemValue.enableIf = option.enableIf;
          }
          return itemValue;
        },
      );

      panel.addQuestion(multipleChoiceProductQuestion);

      // Add a hidden dynamic panel for storing product details
      const productsPanel = new QuestionPanelDynamicModel(
        "multipleChoiceProductPanel",
      );
      productsPanel.titleLocation = "hidden";
      productsPanel.isEditableTemplateElement = false;
      productsPanel.isInteractiveDesignElement = false;
      productsPanel.isContentElement = true;
      productsPanel.visible = false;
      productsPanel.clearIfInvisible = "none";
      ["productId", "price", "invoiceId"].forEach((name) => {
        const hiddenTextQuestion = new QuestionTextModel(name);
        hiddenTextQuestion.visible = false;
        hiddenTextQuestion.clearIfInvisible = "none";
        hiddenTextQuestion.isEditableTemplateElement = false;
        hiddenTextQuestion.isInteractiveDesignElement = false;
        hiddenTextQuestion.isContentElement = true;
        productsPanel.template.addElement(hiddenTextQuestion);
      });

      panel.addElement(productsPanel);
    }
  };

  const buildProductQuestionElementsSingleChoice = (question: Question) => {
    const panel = <PanelModel>question["contentPanel"];
    if (!panel) {
      return;
    }
    const { multipleChoice } = question;
    const options = question.options as ProductsSurveyJSQuestionProductOption[];

    if (multipleChoice !== true) {
      const singleChoiceProductQuestion = new QuestionRadiogroupModel(
        "singleChoiceProductQuestion",
      );
      singleChoiceProductQuestion.titleLocation = "hidden";
      singleChoiceProductQuestion.isEditableTemplateElement = false;
      singleChoiceProductQuestion.isInteractiveDesignElement = false;
      singleChoiceProductQuestion.isContentElement = true;
      const questionValue = <ProductsSurveyJSQuestionProductValue | undefined>(
        question?.getAllValues()?.[question.name]
      );
      singleChoiceProductQuestion.choices = options?.map(
        (option: ProductsSurveyJSQuestionProductOption, index: number) => {
          if (!session?.societyAdminId && questionValue?.invoiceId) {
            singleChoiceProductQuestion.readOnly = true;
          }
          const itemValue = new ItemValue(
            index + 1,
            buildProductQuestionChoiceText(option),
          );
          if (option.visibleIf && option.visibleIf !== "") {
            itemValue.visibleIf = option.visibleIf;
          }
          if (option.enableIf && option.enableIf !== "") {
            itemValue.enableIf = option.enableIf;
          }
          return itemValue;
        },
      );
      if (
        !panel.elements.find(
          (element) => element.name == "singleChoiceProductQuestion",
        )
      ) {
        panel.addElement(singleChoiceProductQuestion);
      }
      if (!panel.elements.find((element) => element.name == "productId")) {
        const productQuestion = new QuestionTextModel("productId");
        productQuestion.value = questionValue?.productId;
        productQuestion.clearIfInvisible = "none";
        productQuestion.visible = false;
        productQuestion.isEditableTemplateElement = false;
        productQuestion.isInteractiveDesignElement = false;
        productQuestion.isContentElement = true;
        panel.addElement(productQuestion);
      }
      if (!panel.elements.find((element) => element.name == "price")) {
        const priceQuestion = new QuestionTextModel("price");
        priceQuestion.value = questionValue?.price;
        priceQuestion.clearIfInvisible = "none";
        priceQuestion.visible = false;
        priceQuestion.isEditableTemplateElement = false;
        priceQuestion.isInteractiveDesignElement = false;
        priceQuestion.isContentElement = true;
        panel.addElement(priceQuestion);
      }
      if (!panel.elements.find((element) => element.name == "invoiceId")) {
        const invoiceQuestion = new QuestionTextModel("invoiceId");
        invoiceQuestion.value = questionValue?.invoiceId;
        invoiceQuestion.clearIfInvisible = "none";
        invoiceQuestion.visible = false;
        invoiceQuestion.isEditableTemplateElement = false;
        invoiceQuestion.isInteractiveDesignElement = false;
        invoiceQuestion.isContentElement = true;
        panel.addElement(invoiceQuestion);
      }
    }
  };

  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      ProductsSurveyJSQuestionName,
    )
  ) {
    ComponentCollection.Instance.add(ProductsSurveyJSQuestionDefinition);
  }
};
