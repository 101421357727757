import { getDisplayNameHelper } from "admin/src/utils/helpers/profile/getDisplayNameHelper";
import Mustache from "mustache";
import { ComponentPropsWithoutRef } from "react";

type MustacheTemplateComponentProps<T> = Omit<
  ComponentPropsWithoutRef<"div">,
  "dangerouslySetInnerHtml"
> & {
  template: string;
  data: T;
};

export const htmlFromMustacheTemplate = <T extends object>({
  template,
  data,
}: MustacheTemplateComponentProps<T>) => {
  return Mustache.render(template, {
    getDisplayNameHelper: getDisplayNameHelper,
    ...data,
  });
};

const MustacheTemplateComponent = <T extends object>({
  template,
  data,
  ...props
}: MustacheTemplateComponentProps<T>) => {
  const html = htmlFromMustacheTemplate({ template, data });
  return <div dangerouslySetInnerHTML={{ __html: html }} {...props} />;
};

export default MustacheTemplateComponent;
