import { IonSpinner } from "@ionic/react";
import SendIcon from "hub/src/components/icons/SendIcon";
import { ComponentPropsWithoutRef, useState } from "react";
import { isNullOrEmpty } from "hub/src/utils/isNullOrEmpty";

type MessageInputProps = Omit<ComponentPropsWithoutRef<"div">, "onSubmit"> & {
  onSubmit: (message: string) => void;
  isLoading: boolean;
  disabled?: boolean;
};

export const MessageInput = ({
  onSubmit,
  isLoading,
  disabled,
  className,
  ...props
}: MessageInputProps) => {
  const [message, setMessage] = useState("");

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (isLoading) {
      return;
    }

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (message.trim() !== "") {
        onSubmit(message);
        setMessage("");
      }
    }
  };

  return (
    <div className={`relative ${className ?? ""}`} {...props}>
      <textarea
        rows={1}
        placeholder="Type Something"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        disabled={disabled}
        autoFocus={true}
        onKeyUp={handleKeyPress}
        className="w-full pr-3 pl-2 !bg-neutral-light-450 disabled:neutral-light-550 border shadow border-neutral-mid-100 resize-none focus:border-neutral-mid-200 text-detailblock-base text-base"
      />
      <div
        className="absolute top-1 right-1"
        onClick={() => {
          if (isNullOrEmpty(message)) {
            return;
          }
          onSubmit(message);
          setMessage("");
        }}
      >
        {isLoading ? (
          <IonSpinner color="primary" />
        ) : (
          <SendIcon className="h-3 w-3 stroke-neutral-mid-400 hover:cursor-pointer" />
        )}
      </div>
    </div>
  );
};
