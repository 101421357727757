import { SocietyTagView } from "admin/src/server/mappers/tag/society-tag";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { Redirect, Route, RouteProps } from "react-router";

type Props = RouteProps & {
  children?: React.ReactNode;
  permissionTags?: SocietyTagView[];
  checkPermission?: boolean;
};

const ProtectedRoute: React.FC<Props> = ({
  children,
  render,
  permissionTags,
  checkPermission,
  ...props
}) => {
  const { societyUserId, profile } = useHubSessionContext();

  if (checkPermission && societyUserId) {
    const hasPermission = permissionTags?.some((permissionTag) =>
      profile?.tags?.some(
        (tag: SocietyTagView) => tag.description === permissionTag.description,
      ),
    );

    if (!hasPermission) {
      return <Redirect to="/access-denied" />;
    }
  }

  if (!societyUserId) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...props}
      render={(props) => {
        if (societyUserId) {
          return render ? render(props) : children;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
